import Footer from './footer';
import Header from './header';
import { useTheme } from 'next-themes';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import pathChecking from '@apps/utils/pathChecking';
import {
  OrganizationJsonLd,
  LocalBusinessJsonLd,
  SocialProfileJsonLd,
} from 'next-seo';

export default function Layout({ children }) {
  const { theme, setTheme } = useTheme();
  const route = useRouter();

  useEffect(() => {
    setTheme('dark');
  }, [setTheme]);

  return (
    <>
      <Header />
      <LocalBusinessJsonLd
        type="LocalBusiness"
        id="https://www.alchemis.com"
        name="Alchemis Technologies"
        description="As a leading Web3 software house, we specialize in blockchain development, smart contract programming, decentralized finance and NFT. We believe in working collaboratively with our clients to create innovative solutions that unlock the full potential of Web3 to drive business growth."
        url="https://www.alchemis.com"
        telephone="+60149686887"
        address={{
          streetAddress: '3-1, Jalan Merbah 1,',
          addressLocality: 'Bandar Puchong Jaya',
          addressRegion: 'Selangor',
          postalCode: '47170',
          addressCountry: 'Malaysia',
        }}
        // address={{
        //   streetAddress: '9 Battery Rd',
        //   addressLocality: 'Singapore',
        //   addressRegion: 'SG',
        //   postalCode: '049910',
        //   addressCountry: 'SG',
        // }}
        geo={{
          latitude: '1.2852308',
          longitude: '103.8524113',
        }}
        images={['https://example.com/logourl']}
      />
      <SocialProfileJsonLd
        type="Person"
        name="Alchemis Technologies"
        url="http://www.alchemis.com"
        sameAs={['https://www.linkedin.com/company/alchemis-web3']}
      />
      <OrganizationJsonLd
        type="Corporation"
        id="https://www.alchemis.com"
        logo="favicon-dark.ico"
        legalName="Alchemis Technologies"
        name="Alchemis"
        address={{
          streetAddress: '9 Battery Rd',
          addressLocality: 'Singapore',
          addressRegion: 'SG',
          postalCode: '049910',
          addressCountry: 'SG',
        }}
        contactPoint={[
          {
            telephone: '+60149686887',
            contactType: 'customer service',
            email: 'info@alchemis.com',
            areaServed: ['SG', 'MY', 'US'],
            availableLanguage: ['English', 'Chinese', 'Cantonese'],
          },
        ]}
        sameAs={['https://www.alchemis.com']}
        url="https://www.alchemis.com/"
      />
      <main>{children}</main>
      <Footer
        expand={pathChecking(route.asPath, [
          '/',
          '/product/tokentool',
          '/technology-stack',
          '/labs',
          '/labs/*',
        ])}
      />
    </>
  );
}
