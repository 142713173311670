const footerMenuList = [
  {
    id: 1,
    title: 'Alchemis Labs',
    diffClass: 'md:col-start-6 md:col-span-3',
    list: [
      {
        id: 1,
        href: '/labs/blockchain-development',
        text: 'Blockchain Development',
      },
      {
        id: 2,
        href: '/labs/smart-contract-development',
        text: 'Smart Contract Development',
      },
      {
        id: 3,
        href: '/labs/dapp-development',
        text: 'dApp Development',
      },
      {
        id: 4,
        href: '/labs/blockchain-consulting',
        text: 'Blockchain Consulting',
      },
      {
        id: 5,
        href: '/labs/tokenization-services',
        text: 'Tokenization Services',
      },
      {
        id: 6,
        href: '/labs/non-fungible-tokens',
        text: 'Non-Fungible Tokens',
      },
    ],
  },
  {
    id: 2,
    title: 'Token Tool',
    diffClass: 'md:col-span-2',
    list: [
      {
        id: 1,
        href: 'https://tokentool.alchemis.com/multi-sender',
        text: 'Batch Send Token',
      },
      {
        id: 2,
        href: 'https://tokentool.alchemis.com/create-multi-wallet',
        text: 'Batch Create Wallet',
      },
      {
        id: 3,
        href: 'https://tokentool.alchemis.com/batch-collection',
        text: 'Batch Collect Token',
      },
      {
        id: 4,
        href: 'https://tokentool.alchemis.com/batch-swap',
        text: 'Batch Token Swap',
      },
      {
        id: 5,
        href: '#',
        text: 'Batch Balance Check',
      },
      {
        id: 6,
        href: '#',
        text: 'BSC Gas Price',
      },
    ],
  },
  {
    id: 3,
    title: 'Company',
    diffClass: 'md:col-span-2',
    list: [
      {
        id: 1,
        href: '/about',
        text: 'About Us',
      },
      {
        id: 2,
        href: '/blog',
        text: 'Blog',
      },
      {
        id: 3,
        href: '/careers',
        text: 'Career',
      },
      {
        id: 4,
        href: '#',
        text: 'Documentation',
      },
    ],
  },
];

const alchemisLabItems = [
  {
    id: 1,
    href: '/labs/blockchain-development',
    text: 'Blockchain Development',
  },
  {
    id: 2,
    href: '/labs/smart-contract-development',
    text: 'Smart Contract Development',
  },
  {
    id: 3,
    href: '/labs/dapp-development',
    text: 'dApp Development',
  },
  {
    id: 4,
    href: '/labs/blockchain-consulting',
    text: 'Blockchain Consulting',
  },
  {
    id: 5,
    href: '/labs/tokenization-services',
    text: 'Tokenization Services',
  },
  {
    id: 6,
    href: '/labs/non-fungible-tokens',
    text: 'Non-Fungible Tokens',
  },
];

const tokenToolItems = [
  {
    id: 1,
    href: 'https://tokentool.alchemis.com/multi-sender',
    text: 'Batch Send Token',
  },
  {
    id: 2,
    href: 'https://tokentool.alchemis.com/create-multi-wallet',
    text: 'Batch Create Wallet',
  },
  {
    id: 3,
    href: 'https://tokentool.alchemis.com/batch-collection',
    text: 'Batch Collect Token',
  },
  {
    id: 4,
    href: 'https://tokentool.alchemis.com/batch-swap',
    text: 'Batch Token Swap',
  },
  {
    id: 5,
    href: 'https://tokentool.alchemis.com/batch-swap',
    text: 'Batch Balance Check',
  },
];

const companyItems = [
  {
    id: 1,
    href: '/about',
    text: 'About Us',
  },
  {
    id: 2,
    href: '/blog',
    text: 'Blog',
  },
  {
    id: 3,
    href: '/careers',
    text: 'Career',
  },
  {
    id: 4,
    href: 'https://tokentool-docs.alchemis.com/',
    text: 'Documentation',
  },
];

const socialIcons = [
  {
    id: 1,
    href: 'https://www.linkedin.com/company/alchemis-web3',
    text: 'linkedin',
  },
  {
    id: 2,
    href: 'https://t.me/alchemis_support_bot ',
    text: 'telegram',
  },
];

export {
  footerMenuList,
  alchemisLabItems,
  companyItems,
  tokenToolItems,
  socialIcons,
};
