/* -------------------------------------------------------------------------- */
/*                      is children page active checking                      */
/* -------------------------------------------------------------------------- */

export function isChildrenPageActive(path, match) {
  if (path && match) {
      if (path === match || path === match?.split('s/[')?.[0]) {
          return true;
      }
      return false;
  }
  return false;
}

/* -------------------------------------------------------------------------- */
/*                       is parent page active checking                       */
/* -------------------------------------------------------------------------- */
export function isParentPageActive(pages, path) {
  if (pages) {
      return pages.some((page) => page.path === path || page.path === path.split('s/[')?.[0]);
  }
  return false;
}
