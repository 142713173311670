
export default function pathChecking(path, match) {
    if (path && match) {
        if (Array.isArray(match)) {
          return match.some((val) => {
            if (val.endsWith('*')) {
              const prefix = val.slice(0, -1);
              return path.startsWith(prefix);
            } else {
              return path === val;
            }
          });
        } else if (path === match) {
            return true;
        }
        return false;
    }
    return false;
}
