import "../styles/globals.css";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { ApolloProvider } from '@apollo/client';
import { store } from "../redux/store";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import { client } from '../utils/createApolloClient';
import { ToastContainer } from 'react-toastify';

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ThemeProvider enableSystem={true} attribute="class">
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </UserContext.Provider>
            <ToastContainer theme={'dark'} className={'toastContainer'}/>
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </>
  );
}

export default MyApp;
